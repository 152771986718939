import React from 'react'
import './header.css'
import { Menu, message } from 'antd';
import { Link, withRouter } from 'react-router-dom'
import redux from '@/redux/store'
import loginOut from '../../../utils/loginOut';
import AJAX from '@/api/api'
import getUrlData from '@/utils/getUrlData'
class header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userImg: "",
            userName: "",
            menuList: [
                {
                    name: '首页',
                    path: '/',
                    type:'old'
                },
                {
                    name: '课程',
                    path: '/class',
                    type:'old'
                },
                {
                    name: '实训',
                    // path: '/practicalTraining',
                    path: '/practicalTraining',
                    type:'new'
                },
                // {
                //     name: '就业',
                //     path: '/2',
                //     type:'old'
                // },
                {
                    name: '资讯',
                    path: '/essay',
                    type:'old'
                },
            ],
            thisPath: ['/'],
        }
    }
    componentDidMount() {
        this.componentWillReceiveProps(this.props)
    }
    //绑定页面与导航栏的对应，使其浏览器返回与前进时，导航栏选择项不会停滞不变，以及进入子页面导航栏选择项缺失
    componentWillReceiveProps(nextProps) {
        let thisPath = nextProps.location.pathname.split('/')[1];
        let toPath = ['']
        if(nextProps.location.pathname=='/'){
            toPath=['/']
        }else{
            this.state.menuList.forEach(item => {
                if (item.path.split('/')[1] == thisPath) {
                    toPath = [item.path]
                }
            })
        }
        
        this.setState({
            thisPath: toPath
        })
    }

    isLogin() {
        let url = AJAX.url()
        if(getUrlData('id')=='null'){
            localStorage.clear()
        }
        if (redux.getState().userInfo) {
            let userName = redux.getState().userInfo.name ? redux.getState().userInfo.name : redux.getState().userInfo.phone
            let photo = redux.getState().userInfo.photo
            return <div className="user">
                <img className="portrait" src={photo}></img>
                <p>{userName}</p>
                {/* <img className="userChange" src={require("../../../assets/images/public/nav_btn_triangle.png")}></img> */}
                {/* <div className='userPopup'> */}
                    {/* <Link to='/setting'>我的设置</Link> */}
                    {/* <Link to='' onClick={()=>loginOut(0)}>退出</Link> */}
                {/* </div> */}
            </div>
        }else{
            return <div className="user">
                <a href={`${url}?openLogin=true`} >登录</a>|<a href={`${url}?openRegist=true`} >注册</a>
            </div>
        }
    }

    render() {
        let url = AJAX.url()
        return (
            <div className="header">
                <div className="content">
                    <img className="logo" src={require("../../../assets/images/public/nav_logo.png")} />
                    <Menu defaultSelectedKeys={['/']} selectedKeys={this.state.thisPath} className="navigationBar" mode="horizontal">
                        {this.state.menuList.map(item =>
                            <Menu.Item key={item.path}>
                            {item.type=='new'?
                                <Link to={{ pathname: item.path }}>{item.name}</Link>
                            :
                                <a href={url+item.path}>{item.name}</a>
                            }
                            </Menu.Item>
                        )}
                    </Menu>

                    <div className="search">
                        <input placeholder="搜索感兴趣的课程"></input>
                        <img src={require("../../../assets/images/public/nav_search.png")}></img>
                    </div>
                    {this.isLogin()}
                </div>
            </div>
        )
    }
}

export default withRouter(header)