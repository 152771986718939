import React from 'react';
import './App.css';
import {Route,Switch} from 'react-router-dom';
import ContentMain from '@/routes/contentMain/contentMain';
import Login from '@/view/login/login'
import Player from '@/view/video/video'
function App() {
  return (
    <Switch>
      {/* 播放视频页面 */}
      <Route exact path="/player" component={Player} />
      {/* 实训 */}
      <Route path="/practicalTraining" component={ContentMain} />
    </Switch>
  );
}

export default App;