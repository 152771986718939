import { message } from "antd";
import redux from '@/redux/store'
import AJAX from '@/api/api'

export default function (e) {
    AJAX.login.logOut().then(res=>{
        localStorage.clear()
        if(e){
            message.error("登录已过期，请重新登录",1,() => {
                window.href = AJAX.url()+'?openLogin=true';
            })
        }else{
            message.success("退出登录成功，请重新登录",1,() => {
                window.href = AJAX.url()+'?openRegist=true';
            })
        }
    })
}